import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="486"
      height="139"
      fill="none"
      viewBox="0 0 486 139"
    >
      <g clipPath="url(#clip0_412_294)">
        <mask
          id="mask0_412_294"
          style={{ maskType: "luminance" }}
          width="124"
          height="125"
          x="0"
          y="9"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#fff" d="M0 9.743h123.122v123.274H0V9.743z"></path>
        </mask>
        <g mask="url(#mask0_412_294)">
          <path
            fill="#001159"
            d="M.072 31.162l5.07-5.334c2.975-2.971 7.977-1.942 7.977-1.942l14.847 2.034 7.521-7.36c3.96-3.89 7.538 2.061 5.981 4.116l-3.86 4.487 15.991 2.96 5.428-6.327c5.8-6.934 10.116-.685 7.498 2.991l-3.505 4.438 24.024 3.132c20.657-19.879 29.092-28.125 34.525-23.225 5.47 4.93-4.207 14.758-23.024 34.143l3.415 24.753 5.035-4.195c2.971-2.476 8.247 1.494 2.799 6.66l-6.923 6.53 2.921 16.202 4.326-3.327c3.821-3.043 8.457.983 3.294 6.5l-6.872 6.924c-.283.21 2.242 14.21 2.242 14.21 1.556 6.862-2.314 9.368-2.314 9.368l-4.719 4.115-17.93-50.65c-1.487-4.454-5.253-9.294-11.544-8.72-3.324.352-11.722 6.023-12.856 7.369L39.975 99.713c-.498.353 2.99 24.307 2.99 24.307 0 .706-6.165 8.791-6.165 8.791L24.478 110.88l-6.084 2.83 3.558-5.539L-.232 95.647l8.32-6.083c3.959.92 24.728 3.254 25.154 3.043 0 0 16.915-16.776 17.88-17.789 5.59-5.872 7.47-8.068 8.317-10.966.85-2.905-.597-7.158-9.367-12.543-12.098-7.429-50-20.147-50-20.147z"
          ></path>
        </g>
      </g>
      <g clipPath="url(#clip1_412_294)">
        <path
          fill="#001159"
          d="M259.029 55.208l-2.842 11.433h-43.361a28.733 28.733 0 000 5.717h41.877l-2.778 11.434h-35.404c4.949 8.538 14.189 14.293 24.748 14.293 7.635 0 14.813-2.974 20.212-8.373l8.085 8.085a39.904 39.904 0 01-12.719 8.575 39.77 39.77 0 01-15.578 3.146 39.774 39.774 0 01-15.579-3.146 39.893 39.893 0 01-12.719-8.575 39.889 39.889 0 01-9.091-14.005h-8.347l2.842-11.434h2.976a40.478 40.478 0 010-5.717h-5.818l2.842-11.433h5.505a39.897 39.897 0 019.091-14.005 39.875 39.875 0 0112.719-8.574 39.768 39.768 0 0115.579-3.147c5.4 0 10.642 1.059 15.578 3.147a39.886 39.886 0 0112.719 8.574l-8.085 8.085c-5.399-5.399-12.577-8.373-20.212-8.373-10.559 0-19.799 5.755-24.748 14.293h42.508zm-22.131-46.24c-33.431 0-60.533 27.1-60.533 60.532 0 33.432 27.102 60.532 60.533 60.532s60.531-27.1 60.531-60.532c0-33.431-27.1-60.532-60.531-60.532z"
        ></path>
      </g>
      <path
        fill="#001159"
        fillRule="evenodd"
        d="M438.637 19.669c2.954 12.221-4.657 24.484-17.002 27.394a23.171 23.171 0 01-17.37-2.745 22.805 22.805 0 01-10.327-14.1 22.55 22.55 0 012.773-17.184c3.188-5.14 8.311-8.814 14.236-10.21 12.336-2.909 24.735 4.63 27.69 16.845z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#001159"
        d="M416.296 69.917l27.52 9.572v13.497l11.39-4.403c2.252-.864 4.116 2.623 1.414 3.665l-14.291 5.518c-4.575 2.703-5.785 7.945-4.353 12.032.209.584.479 1.161.783 1.701-4.718 9.004-12.588 15.696-22.465 21.088-9.887-5.394-17.757-12.084-22.474-21.088.307-.539.577-1.117.783-1.701 1.431-4.071.233-9.301-4.312-12.003l-14.345-5.547c-1.82-.702-.874-4.538 1.406-3.665l11.407 4.403V79.49l27.537-9.572zm-19.773-16.724c-5.727 0-10.157 1.062-13.76 3.693-3.592 2.623-6.22 6.618-8.895 12.139l-11.932 24.6c-1.881 3.89-2.675 7.475-1.719 10.68.946 3.205 3.646 5.61 7.536 7.123l12.588 4.889c2.989 1.162 5.917.92 8.195-.308a8.865 8.865 0 001.115-.719c5.693 10.085 14.742 17.55 25.716 23.366.578.297 1.27.297 1.847 0 10.978-5.816 20.027-13.281 25.717-23.366a9.11 9.11 0 001.117.719c2.279 1.227 5.197 1.47 8.184.308l12.578-4.889c3.9-1.513 6.611-3.918 7.556-7.123.953-3.205.153-6.79-1.728-10.68l-11.923-24.6c-2.674-5.52-5.304-9.516-8.897-12.139-3.599-2.63-8.03-3.693-13.759-3.693h-39.536z"
      ></path>
      <path
        fill="#001159"
        fillRule="evenodd"
        d="M411.608 112.606a1.883 1.883 0 002.653 0l3.55-3.513 13.409-13.587c.73-.73.73-1.899-.011-2.63l-2.799-2.773c-.74-.728-2.133-.505-2.873.216l-12.577 12.588-6.088-6.096a1.854 1.854 0 00-2.636.008l-2.863 2.891a1.897 1.897 0 000 2.656l10.235 10.24z"
        clipRule="evenodd"
      ></path>
      <defs>
        <clipPath id="clip0_412_294">
          <path
            fill="#fff"
            d="M0 0H127.043V127.043H0z"
            transform="translate(0 5.979)"
          ></path>
        </clipPath>
        <clipPath id="clip1_412_294">
          <path
            fill="#fff"
            d="M0 0H121.065V121.065H0z"
            transform="translate(176.365 8.967)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
